import React, { useMemo } from "react";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import GenericMap from "../map/GenericMap";
import { getBoundBox } from "../../utils/turfHelpers";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";

const TractFilter = ({ data, filter, setFilter }) => {

  const map = useMemo(() => {
    if (!data) {
      return null;
    }

    const allPoints = [];
    (data || [])
      .filter((c) => c?.geometry?.coordinates?.length)
      .forEach((c) => {
        if (c.geometry.type === "MultiPolygon") {
          c.geometry.coordinates
            .reduce((acc, [arr]) => [...acc, ...arr], [])
            .forEach((p) => allPoints.push(p));
        } else
          (c.geometry.coordinates[0] || []).forEach((p) => allPoints.push(p));
      });
    const viewport = calculatePolygonViewport({
      type: "Polygon",
      coordinates: [allPoints],
    }) || {
      latitude: 41.5886072190021,
      longitude: -103.58719705449793,
      zoom: 12.950430835225765,
    };

    const certGeo = convertToFeatureCollection(data, "Alias", ["Category"]);
    let selectedGeo = [];
    (certGeo?.features || []).forEach((item) => {
      const isSelected = filter.tract.includes(item.properties.id);
      item.properties.isSelected = isSelected;
      if (isSelected) {
        selectedGeo.push(item);
      }
    });
    let bboxMap;
    try {
      bboxMap = getBoundBox([
        ...(selectedGeo.length ? selectedGeo : certGeo.features),
      ]);
    } catch (e) {
      console.warn(e);
    }
    const shouldAddBBox = bboxMap?.some(
      (i) => i !== Infinity && i !== -Infinity
    );
    return (
      <GenericMap
        certGeo={certGeo}
        viewportOverride={viewport}
        expandable
        irrigatedEditable
        editingEndpoint="certs"
        bboxMap={shouldAddBBox ? bboxMap : null}
        onIconClick={(type, id) => {
          if (filter.tract.includes(id)) {
            setFilter({
              ...filter,
              tract: filter.tract.filter((t) => t !== id),
            });
          } else {
            setFilter({
              ...filter,
              tract: [...filter.tract, id],
            });
          }
        }}
      />
    );
  }, [data, filter, setFilter]);

  return map;
};

export default TractFilter;
