import React from "react";
import AppRouter from "./router";
import { RouterProvider } from "react-router-dom";
import { AppComposite } from "./context";
import SmartBannerWrapper from "./components/common/SmartBannerWrapper";

function App() {
  return (
    <AppComposite>
      <SmartBannerWrapper/>
      <RouterProvider router={AppRouter} />
    </AppComposite>
  );
}

export default App;
