import React, { useState } from "react";
import Button from "../common/Button";
import Input from "../common/form/Input";
import DatePicker from "../common/form/DatePicker";
import Select from "../common/form/Select";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Toggle from "../common/Toggle";



const handleAdvancedToggle = (formik, config, index, key, value) => {

  formik.setFieldValue(key, value);

  if (key === `${config.prefix}-${index + 1}-advanced` && value === true) {
    const nitrogenAppliedKey = `${config.prefix}-${index + 1}-direct_nitrogen`;
    formik.setFieldValue(nitrogenAppliedKey, "");
  } else if (key === `${config.prefix}-${index + 1}-advanced` && value === false){
    const n = `${config.prefix}-${index + 1}-n_percent`;
    const p = `${config.prefix}-${index + 1}-p_percent`;
    const k = `${config.prefix}-${index + 1}-k_percent`;
    const d = `${config.prefix}-${index + 1}-n_density`;
    const t = `${config.prefix}-${index + 1}-type`;
    const a = `${config.prefix}-${index + 1}-amount`;
    const u = `${config.prefix}-${index + 1}-units`;

    const setNull = [n,p,k,d,t,a,u];

    setNull.forEach((fieldKey) => {
      if (formik.values[fieldKey] !== undefined) {
        formik.setFieldValue(fieldKey, ""); 
      }
    });
  }

  if (key === `${config.prefix}-${index + 1}-inhibitor_used` && value === false) {
    const inhibitorAppliedKey = `${config.prefix}-${index + 1}-inhibitor_type`;
    formik.setFieldValue(inhibitorAppliedKey, "");
  }
};

const handlePercentInput = (formik, config, index, key, value) => {

  const n = `${config.prefix}-${index + 1}-n_percent`;
  const p = `${config.prefix}-${index + 1}-p_percent`;
  const k = `${config.prefix}-${index + 1}-k_percent`;

  if (!isNaN(value)) {
    if (key === n || key === p || key === k) {
      formik.setFieldValue(key, value / 100);
    } else {
      formik.setFieldValue(key, value);
    }
  } else {
    formik.setFieldValue(key, value);
  }
};

const percentValueToShow = (config, index, key, value) => {
  const n = `${config.prefix}-${index + 1}-n_percent`;
  const p = `${config.prefix}-${index + 1}-p_percent`;
  const k = `${config.prefix}-${index + 1}-k_percent`;

  if (value === null || value === undefined) {
    return "";
  }

  if (key === n || key === p || key === k){
    return value * 100;
  } else {
    return value;
  }

}

const ApplicationItem = ({ index, handleDeleteRow, config, formik, error }) => {
  const [expand, setExpand] = useState(true);

  return (
    <div className="mb-4">
      <div
        className={`flex items-center justify-between px-4 py-2 cursor-pointer bg-gray-f2 rounded-md ${
          error?.inValidIndex && error.inValidIndex.indexOf(index + 1) !== -1
            ? "border rounded-md border-red-600"
            : ""
        }`}
        onClick={() => setExpand(!expand)}
      >
        <span>Nitrogen Application {index + 1}</span>
        <div className="flex items-center gap-x-3">
          {index > 0 && (
            <MinusCircleIcon
              className="w-5 h-5 cursor-pointer text-red-600"
              onClick={() => handleDeleteRow(index)}
            />
          )}
          {expand ? (
            <ChevronDownIcon className="w-5 h-5 inline-block" />
          ) : (
            <ChevronUpIcon className="w-5 h-5 inline-block" />
          )}
        </div>
      </div>
      {expand && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 p-4">
          {config.fields.map((field) => {
            const {
              key,
              date,
              dropdown,
              options,
              label,
              bool,
              condition,
              suffix,
              comment,
              secondaryLabel,
              type,
            } = field;
            const inputComment = comment && (
              <div className="text-sm text-gray-500">{comment}</div>
            );
            const formKey = `${config.prefix}-${index + 1}-${key}`;
            
            if (!condition) {
              if (date) {
                return (
                  <div key={formKey} className="col-span-1">
                    <DatePicker
                      name={formKey}
                      inputClassName="h-[38px]"
                      value={formik.values[formKey]}
                      label={label}
                      onChange={(e) =>
                        formik.setFieldValue(formKey, e.target.value)
                      }
                    />
                  </div>
                );
              } else if (dropdown) {
                return (
                  <div key={formKey} className="col-span-1">
                    <Select
                      extraOptions={options}
                      onChange={(option) => {
                        formik.setFieldValue(formKey, option);
                      }}
                      label={label}
                      value={formik.values[formKey]}
                      className="w-full"
                    />
                    {formik.errors[formKey] && (
                      <div className="text-xs text-red-600 mt-1 w-full">
                        {formik.errors[formKey]}
                      </div>
                    )}
                  </div>
                );
              } else if (bool) {
                return (
                  <div
                    key={formKey}
                    className="col-span-1 flex items-center justify-center"
                  >
                    <Toggle
                      key={key}
                      value={formik.values[formKey]}
                      label={label}
                      onChange={(value) => {
                        handleAdvancedToggle(formik, config, index, formKey, value);
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={formKey} className="col-span-1">
                    <Input
                      name={formKey}
                      inputClassName="text-center px-0 h-[38px]"
                      value={formik.values[formKey]}
                      label={label}
                      suffix={suffix}
                      secondaryLabel={secondaryLabel}
                      type={type}
                      disabled={key === 'direct_nitrogen' && formik.values[`${config.prefix}-${index + 1}-advanced`] === true}
                      onChange={(e) => {
                        formik.setFieldValue(formKey, e.target.value);
                        formik.setFieldTouched(formKey);
                      }}
                    />
                    {inputComment}
                  </div>
                );
              }
            } else if (
              condition &&
              formik.values[`${config.prefix}-${index + 1}-inhibitor_used`] ===
                true &&
              condition !== "advanced"
            ) {
              return (
                <div key={formKey} className="col-span-1">
                  <Select
                    extraOptions={options}
                    onChange={(option) => {
                      formik.setFieldValue(formKey, option);
                    }}
                    label={label}
                    value={formik.values[formKey]}
                    className="w-full"
                  />
                  {formik.errors[formKey] && (
                    <div className="text-xs text-red-600 mt-1 w-full">
                      {formik.errors[formKey]}
                    </div>
                  )}
                </div>
              );
            } else if (
              condition &&
              formik.values[`${config.prefix}-${index + 1}-advanced`] ===
                true &&
              condition !== "inhibitor_used"
            ) {
              if (dropdown) {
                return (
                  <div key={formKey} className="col-span-1">
                    <Select
                      extraOptions={options}
                      onChange={(option) => {
                        formik.setFieldValue(formKey, option);
                      }}
                      label={label}
                      value={formik.values[formKey]}
                      className="w-full"
                    />
                    {formik.errors[formKey] && (
                      <div className="text-xs text-red-600 mt-1 w-full">
                        {formik.errors[formKey]}
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div key={formKey} className="col-span-1">
                    <Input
                      name={formKey}
                      inputClassName="text-center px-0 h-[38px]"
                      value={percentValueToShow(config, index, formKey, formik.values[formKey])}
                      label={label}
                      suffix={suffix}
                      secondaryLabel={secondaryLabel}
                      type={type}
                      onChange={(e) => {
                        handlePercentInput(formik, config, index, formKey, e.target.value);
                        formik.setFieldTouched(formKey);
                      }}
                    />
                    {formik.errors[formKey] && (
                      <div className="text-xs text-red-600 mt-1 w-full">
                        {formik.errors[formKey]}
                      </div>
                    )}
                  </div>
                );
              }
            } else {
              return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

const CropNitrateApplicationNew = (props) => {
  const { formik, config, numberApp, setNumberApp } = props;
  const handleDeleteRow = (index) => {
    for (let i = index; i < numberApp; i++) {
      config.fields.forEach(({ key }) => {
        const formKey = `${config.prefix}-${i + 1}-${key}`;
        if (formik.values[formKey]) {
          if (i !== index) {
            formik.setFieldValue(
              `${config.prefix}-${i}-${key}`,
              formik.values[formKey]
            );
          }
          formik.setFieldValue(formKey, undefined);
        }
      });
    }
    setNumberApp(numberApp - 1);
  };

  return (
    <div>
      <div className="overflow-y-auto nitrateAppForm">
        {Array(numberApp)
          .fill("")
          .map((item, index) => (
            <ApplicationItem
              key={`nitrate_app_${index}`}
              index={index}
              handleDeleteRow={handleDeleteRow}
              {...props}
            />
          ))}
      </div>
      <div className="flex justify-end mt-3">
        <Button
          onClick={(e) => {
            e.preventDefault();
            setNumberApp(numberApp + 1);
          }}
        >
          {config.prefix === "nitrateApp"
            ? "Add Application Event"
            : "Add Application"}
        </Button>
      </div>
    </div>
  );
};

export default CropNitrateApplicationNew;
