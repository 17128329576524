export const apiRootURL =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_ROOT_URL
        : 'https://devapi.producerconnect.com';

export const userPoolId = "us-east-2_ArSLBaQg9";
  // process.env.NODE_ENV === "production"
  //   ? process.env.REACT_APP_USER_POOL_ID
  //   : "us-east-2_ArSLBaQg9";

export const userPoolWebClientId = "3e63r6mg88ucd9nulb7oro2242";
  // process.env.NODE_ENV === "production"
  //   ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  //   : "3e63r6mg88ucd9nulb7oro2242";
