import React, { useState, useMemo } from "react";
import NitrogenCostChart from "./NitrogenCostChart";
import SliderWrapper from "../common/SliderWrapper";

const CostAnalysis = ({ selectedTract }) => {
  const [nPricePerLb, setNPricePerLb] = useState("0.50");
  const initialCornPriceBu = "4.00";
  const [cornPriceBu, setCornPriceBu] = useState(initialCornPriceBu);
  const [sliderValue, setSliderValue] = useState(4);
  const [sliderMinMax, setSliderMinMax] = useState({
    min: 3.5,
    max: 4.5,
  });

  const formatToTwoDecimalPlaces = (value) => {
    if (value === "" || isNaN(value)) {
      return value;
    }
    return parseFloat(value).toFixed(2);
  };

  const handleNPriceInputChange = (e) => {
    const typedValue = e.target.value;
    setNPricePerLb(typedValue);
  };

  const handleNPriceBlur = () => {
    setNPricePerLb(formatToTwoDecimalPlaces(nPricePerLb));
  };

  const handleCornPriceInputChange = (e) => {
    const typedValue = e.target.value;
    setCornPriceBu(typedValue);

    const parsedValue = parseFloat(typedValue);
    if (!isNaN(parsedValue)) {
      const newMin = parsedValue - 0.5;
      const newMax = parsedValue + 0.5;
      setSliderMinMax({ min: newMin, max: newMax });

      const midPoint = (newMin + newMax) / 2;
      setSliderValue(midPoint);
    }
  };

  const handleCornPriceBlur = () => {
    setCornPriceBu(formatToTwoDecimalPlaces(cornPriceBu));
  };

  const handleSliderChange = (event, newValue) => {
    const parsedValue = parseFloat(newValue);
    if (!isNaN(parsedValue)) {
      setSliderValue(parsedValue);
      setCornPriceBu(formatToTwoDecimalPlaces(parsedValue));
    }
  };

  const chartDataPoint = useMemo(() => {
    const cornPrice = parseFloat(cornPriceBu);
    const nPrice = parseFloat(nPricePerLb);

    if (isNaN(cornPrice) || isNaN(nPrice) || nPrice === 0) {
      return null;
    }

    const ratio = cornPrice / nPrice;
    const adjustmentFactor =
      0.263 + 0.1256 * ratio - 0.00421 * Math.pow(ratio, 2);

    return {
      ratio: ratio,
      adjustmentFactor: adjustmentFactor,
    };
  }, [cornPriceBu, nPricePerLb]);

  return (
    <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 px-3 py-5 flex flex-col xl:flex-row gap-x-3">
      <div className="flex-1 xl:basis-1/3">
        <div className="mb-3 font-bold text-xl">Nitrogen Cost Analysis</div>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col justify-start h-full my-8">
            <div className="flex items-center mb-3">
              <label className="mr-2">Nitrogen Price / lbs: $</label>
              <input
                type="text"
                value={nPricePerLb}
                onChange={handleNPriceInputChange}
                onBlur={handleNPriceBlur}
                className="border rounded p-1 w-20"
              />
            </div>
          </div>
          <div className="flex flex-col justify-start h-full">
            <div className="flex items-center mb-3">
              <label className="mr-2">Corn Grain Price / bu: $</label>
              <input
                type="text"
                value={cornPriceBu}
                onChange={handleCornPriceInputChange}
                onBlur={handleCornPriceBlur}
                className="border rounded p-1 w-20"
              />
            </div>
            <div className="flex-grow w-full flex justify-center items-center mx-3">
              <SliderWrapper
                label="Corn Price Slider"
                min={sliderMinMax.min}
                max={sliderMinMax.max}
                value={sliderValue}
                onChange={handleSliderChange}
                step={0.01}
                marks={[{ value: sliderMinMax.min, label: '-$0.50',},{value: sliderMinMax.max,label: '+$0.50',},]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 xl:basis-2/3 mt-5 xl:mt-0 h-full flex justify-center items-center">
        <NitrogenCostChart
          id="nitrogen-total-cost-chart"
          chartDataPoint={chartDataPoint}
          title="Price adjustment factor based on Corn $/bu: N price $/lb N"
        />
      </div>
    </div>
  );
};

export default CostAnalysis;