import React, { useMemo, useState } from "react";
import Modal from "../common/Modal";
import Input from "../common/form/Input";
import Select from "../common/form/Select";
import Toggle from "../common/Toggle";
import { FormikProvider, useFormik } from "formik";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import Button from "../common/Button";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { getCropSchema } from "../../utils/validation";
import { steps, inhibitorConfig, applicationConfig } from "./configs";
import Navigation from "../common/Navigation";
import CropNitrateApplication from "./CropNitrateApplication";
import fileUpload from "../../utils/fileUpload";
import DatePicker from "../common/form/DatePicker";
import CropNitrateApplicationNew from "./CropNitrateApplicationNew";

const formatData = (values, totalAcres) => {
  return Object.entries(values).reduce((result, [key, value]) => {
    if (key.includes("Percent_")) {
      result[key.replace("Percent_", "")] = parseFloat(
        ((value * totalAcres) / 100).toFixed(2)
      );
    }
    if (
      key.includes(applicationConfig.prefix) ||
      key.includes(inhibitorConfig.prefix)
    ) {
      const [prefix, index, property] = key.split("-");
      const parsedIndex = parseInt(index);
      const nitrateKeyData =
        prefix === inhibitorConfig.prefix
          ? "Nitrate_Inhibitors"
          : "Nitrate_Applications";
      if (!result[nitrateKeyData]) {
        result[nitrateKeyData] = [];
      }
      if (!result[nitrateKeyData][parsedIndex - 1]) {
        result[nitrateKeyData][parsedIndex - 1] = {};
      }
      let valueToSave = value;
      const configs = key.includes(applicationConfig.prefix)
        ? applicationConfig
        : inhibitorConfig;
      (configs.fields || []).forEach((field) => {
        if (field.key === property) {
          if (field.number) {
            valueToSave = parseFloat(value);
          } else if (field.date) {
            valueToSave = new Date(value).toISOString();
          }
        }
      });
      result[nitrateKeyData][parsedIndex - 1][property] = valueToSave;
    } else if (key.includes("manureApplications")) {
      const [, manureDataKey] = key.split("-");
      if (!result.manureApplications) {
        result["manureApplications"] = [{}];
      }
      let valueToSave = value;
      if (!isNaN(value)) {
        valueToSave = parseFloat(value);
      } else if (manureDataKey === "date_applied") {
        valueToSave = new Date(value).toISOString();
      }
      result["manureApplications"][0][manureDataKey] = valueToSave;
    } else {
      let valueToSave = value;
      steps.forEach((step) => {
        (step.fields || []).forEach((field) => {
          if (field.key === key) {
            if (field.number) {
              valueToSave = parseFloat(value);
            } else if (field.date) {
              valueToSave = new Date(value).toISOString();
            }
          }
        });
      });
      result[key] = valueToSave;
    }
    return result;
  }, {});
};

const isValidDropdownValue = (value) =>
  value !== undefined && value !== null && value !== "";

const calculateTotalAcres = (values, tractAcres) => {
  return (
    (parseFloat(values.Sprinkler_Acres) || 0) +
    (parseFloat(values.Flood_Acres) || 0) +
    (parseFloat(values.Other_Irr_Acres) || 0) +
    ((parseFloat(values.Percent_Sprinkler_Acres) || 0) * tractAcres) / 100 +
    ((parseFloat(values.Percent_Flood_Acres) || 0) * tractAcres) / 100 +
    ((parseFloat(values.Percent_Other_Irr_Acres) || 0) * tractAcres) / 100
  );
};

const CropAddNewModalContent = ({
  formik,
  isLoading,
  tractAcres,
  activeStep,
  setActiveStep,
  error,
  nextStep,
  previousStep,
  currentStep,
  numberApp,
  setNumberApp,
  numberInhibitor,
  setNumberInhibitor,
  file,
  setFile,
}) => {
  function onDrop(file) {
    setFile(file);
  }

  const stepTitle = useMemo(() => {
    const { values } = formik;
    let titleToShow = currentStep.title;
    if (titleToShow.includes("$$crop")) {
      titleToShow = titleToShow.replace("$$crop", values.Crop_Planted);
    }
    if (titleToShow.includes("$$totalAcres")) {
      const acres = calculateTotalAcres(values, tractAcres);
      titleToShow = titleToShow.replace("$$totalAcres", acres);
    }
    return titleToShow;
  }, [currentStep.title, formik, tractAcres]);

  const renderItem = (
    {
      label,
      key,
      suffix,
      bool,
      textarea,
      number,
      dropdown,
      options,
      comment,
      elementClassName,
      percent,
      hasPercent,
      date,
    },
    index,
    isStepActive
  ) => {
    const labelToShow = label || key.split("_").join(" ");

    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    if (bool) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
          {inputComment}
        </div>
      );
    }
    if (date) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <DatePicker
            name={key}
            label={label}
            inputClassName="h-[38px]"
            value={formik.values[key]}
            onChange={(e) => formik.setFieldValue(key, e.target.value)}
          />
        </div>
      );
    }
    if (dropdown) {
      return (
        <React.Fragment key={`${key}_${index}`}>
          {percent && (
            <div className="col-span-1 text-lg font-bold flex items-center justify-center">
              OR
            </div>
          )}
          <div className={elementClassName || "col-span-1"}>
            <Select
              id={key}
              name={key}
              label={labelToShow}
              extraOptions={options}
              onChange={(option) => {
                formik.setFieldValue(key, option);
                if (percent) {
                  formik.setFieldValue(key.replace("Percent_", ""), undefined);
                }
              }}
              isDisabled={
                (percent && formik.values[key.replace("Percent_", "")]) ||
                !isStepActive
              }
              value={formik.values[key]}
              {...(percent
                ? {
                    getOptionLabel: (option) => {
                      return isValidDropdownValue(option.label) ? (
                        <span className="text-black dark:text-white">
                          {option.label}%
                          {isValidDropdownValue(option.value) && (
                            <span className="text-gray-500 ml-3">
                              {((option.value * tractAcres) / 100).toFixed(2)}{" "}
                              acres
                            </span>
                          )}
                        </span>
                      ) : (
                        ""
                      );
                    },
                  }
                : {})}
            />
            {inputComment}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(key, e.target.value);
            formik.setFieldTouched(key);
            if (hasPercent) {
              formik.setFieldValue(`Percent_${key}`, undefined);
            }
          }}
          disabled={
            formik.values[`Percent_${key}`] ||
            formik.values[`Percent_${key}`] === 0 ||
            (key === "Corn_Yield_Goal" && formik.values.Crop_Planted !== "Corn")
          }
        />
        {inputComment}
      </div>
    );
  };

  return (
    <div className="p-2 flex">
      <Navigation
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        className="w-1/3"
      />
      <div className="w-2/3 bg-white dark:bg-gray-600 rounded-lg">
        <div className="relative p-4 border-b border-gray-200 text-center flex items-center justify-center">
          <div className="text-lg">{stepTitle}</div>
          {activeStep !== 0 && (
            <ChevronLeftIcon
              width={40}
              height={40}
              className="absolute left-0 cursor-pointer"
              onClick={previousStep}
            />
          )}
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              {steps.map((step, stepIdx) => {
                if (
                  step.type === "nitrate_inhibitor" ||
                  step.type === "nitrate_application"
                ) {
                  return (
                    <div
                      key={stepIdx}
                      className={twMerge(
                        "w-full opacity-0",
                        step.className,
                        activeStep === stepIdx
                          ? "visible opacity-100 content_active"
                          : "invisible h-0 m-0"
                      )}
                    >
                      <CropNitrateApplicationNew
                        formik={formik}
                        error={error}
                        config={
                          step.type === "nitrate_application"
                            ? applicationConfig
                            : inhibitorConfig
                        }
                        numberApp={
                          step.type === "nitrate_application"
                            ? numberApp
                            : numberInhibitor
                        }
                        setNumberApp={
                          step.type === "nitrate_application"
                            ? setNumberApp
                            : setNumberInhibitor
                        }
                      />
                    </div>
                  );
                } else if (step.type === "submit") {
                  return (
                    <div
                      key={stepIdx}
                      className={twMerge(
                        "flex flex-col justify-center items-center opacity-0",
                        step.className,
                        activeStep === stepIdx
                          ? "visible opacity-100 content_active"
                          : " invisible h-0 m-0"
                      )}
                    >
                      <div className="flex items-center">
                        <Button
                          type="button"
                          priority="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            fileUpload(
                              ".jpeg,.jpg,.png,.tiff,.pdf,.docx",
                              onDrop
                            );
                          }}
                        >
                          Upload Application and Inhibitor Documents
                        </Button>
                        {file && <div className="ml-3 italic">{file.name}</div>}
                      </div>
                      <Button
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                        className="my-5 w-fit float-right"
                      >
                        Submit
                      </Button>
                    </div>
                  );
                }
                return (
                  <div
                    key={stepIdx}
                    className={twMerge(
                      "grid grid-cols-1 md:grid-cols-2 gap-5 opacity-0",
                      step.className,
                      activeStep === stepIdx
                        ? "visible opacity-100 content_active"
                        : "invisible h-0 m-0"
                    )}
                  >
                    {step.fields.map((item, index) =>
                      renderItem(item, index, activeStep === stepIdx)
                    )}
                  </div>
                );
              })}
              <div className="flex items-center justify-end mt-5">
                <Button
                  type="button"
                  className={`w-fit ${
                    activeStep < steps.length - 1 ? "" : "invisible"
                  }`}
                  onClick={nextStep}
                >
                  Next
                </Button>
              </div>
              <span
                className={twMerge(
                  "mt-2 text-red-500 invisible opacity-0",
                  error ? "visible opacity-100 content_active" : "h-0"
                )}
              >
                {error ? typeof error === 'object' ? error.isValid : error : null}
              </span>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};

const CropAddNew = ({ open, setIsOpen, tractId, refreshData, tractAcres }) => {
  const [isLoading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState();
  const [numberApp, setNumberApp] = useState(1);
  const [numberInhibitor, setNumberInhibitor] = useState(1);
  const [file, setFile] = useState(null);

  const currentStep = steps[activeStep];

  function nextStep(e) {
    e?.preventDefault();
    let isValid = true;
    if (currentStep.validation) {
      isValid = currentStep.validation(
        formik,
        tractAcres,
        numberApp,
        numberInhibitor
      );
    }
    if (isValid === true) {
      if (activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1);
      }
      setError(null);
      return true;
    } else {
      setError(isValid);
      if (
        currentStep.type !== "nitrate_application" &&
        currentStep.type !== "nitrate_inhibitor"
      ) {
        formik.validateForm();
      }
      return false;
    }
  }

  const previousStep = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
    setError(null);
  };

  const formik = useFormik({
    initialValues: {
      Year: new Date().getFullYear(),
      Crop_Yield_Units: "bushels / ac",
    },
    validationSchema: getCropSchema(tractAcres),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!nextStep()) {
          return;
        }
        setLoading(true);
        const result = await request({
          method: "post",
          url: `/tracts/${tractId}/AddCrop`,
          data: formatData(values, tractAcres),
        });
        if (result && file) {
          const formData = new FormData();
          formData.append("file", file);
          const headers = { "Content-Type": "multipart/form-data" };
          await request({
            method: "post",
            url: `/tracts/${tractId}/crops/${result}/AddCropFile`,
            data: formData,
            headers,
          });
        }
        refreshData();
        toast.success(
          `Successfully added crop.${
            calculateTotalAcres(values, tractAcres) < tractAcres
              ? " Please add another report for the remaining acres."
              : ""
          }`,
          { duration: 5000 }
        );
        setLoading(null);
        setIsOpen(false);
        resetForm();
        setActiveStep(0);
        setNumberApp(1);
        setNumberInhibitor(1);
        setFile(null);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      isOpen={open}
      close={() => {
        setIsOpen(false);
      }}
      Content={CropAddNewModalContent}
      className="bg-[#d9d9d9] sm:max-w-7xl h-full"
      contentProps={{
        formik,
        isLoading,
        tractAcres,
        activeStep,
        setActiveStep,
        error,
        nextStep,
        previousStep,
        currentStep,
        numberApp,
        setNumberApp,
        numberInhibitor,
        setNumberInhibitor,
        file,
        setFile,
      }}
    />
  );
};

export default CropAddNew;
