import React, { useEffect, useMemo, useState } from "react";
import TableData from "../table";
import { useLazyFetch } from "../../hooks/useFetch";
import {
  ArrowDownCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Select from "../common/form/Select";
import TractFilter from "./TractFilter";
import ConfirmModal from "../common/ConfirmModal";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { getYearOptions } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const ReportPage = () => {
  const [showTractFilter, setShowTractFilter] = useState(0);
  const [deleteReport, setDeleteReport] = useState(null);
  const [filter, setFilter] = useState({
    tract: [],
    status: "All Status",
    year: "Year",
    cert_type: "Tract Type",
  });

  const navigate = useNavigate();

  const [fetchReport, { data, loading }] = useLazyFetch("");

  const handleShowFilterPopup = () => {
    if (showTractFilter === 1) {
      setShowTractFilter(0);
    } else {
      setShowTractFilter(1);
    }
  };

  const handlePinFilter = () => {
    setShowTractFilter(2);
  };

  const hideFilter = () => {
    setShowTractFilter(0);
  };

  const handleDeleteReport = async () => {
    try {
      await request({ method: "delete", url: `/reports/${deleteReport}` });
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setDeleteReport(null);
    }
  };

  const handleClickReport = (item) => {
    if (item.status !== "Done") {
      navigate(`/reports/${item._id}`);
    }
  };

  useEffect(() => {
    fetchReport(`/reports`);
  }, []);

  const reportData = useMemo(() => {
    return data?.filter(
      (item) =>
        (!filter.tract.length || filter.tract.includes(item._id)) &&
        (filter.status === "All Status" || filter.status === item.status) &&
        (filter.year === "Year" || filter.year === item.year) &&
        (filter.cert_type === "Tract Type" ||
          filter.cert_type === item.cert_type)
    );
  }, [data, filter]);

  return (
    <div className="w-full min-h-screen">
      <div className="rounded-lg bg-white dark:bg-gray-900 w-full p-5 pb-10">
        <div className="border-b-2 border-b-zinc-200 pb-4">
          <h2 className="font-bold text-2xl dark:text-white">Reports</h2>
          <p className="text-gray-1 mt-2">
            Tract, manage, and create tract reports
          </p>
        </div>
        <div className="flex items-center mt-4 gap-x-4">
          <Select
            extraOptions={["All Status", "Due", "In Progress", "Completed"]}
            onChange={(option) => {
              setFilter({ ...filter, status: option });
            }}
            className="w-40"
            value={filter.status}
          />
          {showTractFilter !== 2 && (
            <button
              className="flex items-center justify-between ring-1 ring-inset ring-gray-300 rounded-md w-32"
              onClick={handleShowFilterPopup}
            >
              <span className="p-2">Tract</span>
              <div className="flex items-center">
                <span className="h-5 w-[1px] bg-gray-300" />
                <span className="p-2">
                  {showTractFilter === 1 ? (
                    <ChevronUpIcon color="#cccccc" className="w-5 h-5" />
                  ) : (
                    <ChevronDownIcon color="#cccccc" className="w-5 h-5" />
                  )}
                </span>
              </div>
            </button>
          )}
          <Select
            extraOptions={["Year", ...getYearOptions()]}
            onChange={(option) => {
              setFilter({ ...filter, year: option });
            }}
            className="w-32"
            value={filter.year}
          />
          <Select
            extraOptions={["Tract Type", "Irrigation", "Municipal"]}
            onChange={(option) => {
              setFilter({ ...filter, cert_type: option });
            }}
            className="w-40"
            value={filter.cert_type}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-x-5 mt-10">
          {showTractFilter !== 0 && (
            <div
              className={`${
                showTractFilter === 1
                  ? "tractFilterPopup"
                  : "w-full lg:flex-1 h-[472px]"
              } flex flex-col`}
            >
              <div className="flex items-center justify-between border-b border-b-zinc-200 pb-3">
                <div>
                  <div className="font-bold">Tract Filter</div>
                  <div className="text-sm">Select a tract to filter by</div>
                </div>
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={showTractFilter === 1 ? handlePinFilter : hideFilter}
                >
                  {showTractFilter === 1 ? (
                    <>
                      <ArrowDownCircleIcon className="w-5 h-5" />
                      <span>Pin to Reports Page</span>
                    </>
                  ) : (
                    <>
                      <XMarkIcon className="w-5 h-5" />
                      <span>Close Map</span>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-5 h-full">
                <TractFilter
                  data={data}
                  filter={filter}
                  setFilter={setFilter}
                />
              </div>
            </div>
          )}
          <div className="w-full lg:flex-1 mt-4 lg:mt-0 border border-gray-1 rounded-md p-2">
            <TableData
              enableScrollIntoItem
              data={reportData || []}
              page={1}
              headerItems={
                <>
                  <div className="flex-1 text-left pl-3">ALIAS</div>
                  <div className="flex-1 text-left">STATUS</div>
                  <div className="flex-1 text-left">YEAR</div>
                  <div className="flex-1 text-left">TYPE</div>
                  <div className="flex-1 text-center"></div>
                </>
              }
              renderRow={(item, index) => (
                <div
                  className={`flex w-full py-4 cursor-pointer`}
                  onClick={() => handleClickReport(item)}
                >
                  <div className="flex-1 pl-3">{item.Alias}</div>
                  <div className="flex-1">
                    <div
                      className={`w-fit rounded-md px-3 py-1 flex items-center text-white font-bold gap-x-1 ${
                        item.status === "Due"
                          ? "bg-red-600"
                          : item.status === "In Progress"
                          ? "bg-orange-400"
                          : item.status === "Done"
                          ? "bg-primary-light"
                          : "bg-gray-1"
                      }`}
                    >
                      {item.status === "Due" ? (
                        <XCircleIcon className="w-5 h-5" />
                      ) : item.status === "In Progress" ? (
                        <ArrowRightCircleIcon className="w-5 h-5" />
                      ) : item.status === "Done" ? (
                        <CheckCircleIcon className="w-5 h-5" />
                      ) : null}
                      {item.status}
                    </div>
                  </div>
                  <div className="flex-1">{item.year}</div>
                  <div className="flex-1">{item.cert_type}</div>
                  <div className="flex-1 flex justify-center">
                    <PlusCircleIcon className="w-5 h-5 cursor-pointer" />
                    <PencilSquareIcon
                      className="w-5 h-5 cursor-pointer ml-2"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <TrashIcon
                      className="w-5 h-5 ml-2 cursor-pointer"
                      color={item.status === "Completed" ? "#939393" : ""}
                      onClick={() => {
                        if (item.status === "In Progress") {
                          setDeleteReport(item.report_id);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={!!deleteReport}
        toggle={() => {
          setDeleteReport(null);
        }}
        message="Are you sure you want to delete this report?"
        onConfirm={handleDeleteReport}
        warning={true}
      />
    </div>
  );
};

export default ReportPage;
