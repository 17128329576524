import React from 'react';
import { Slider } from "@mui/material";

const COMPONENT_TYPES = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

const SliderWrapper = ({
  label,
  min,
  max,
  defaultVal,
  type = COMPONENT_TYPES.HORIZONTAL,
  step,
  onChange,
  value,
  marks
}) => {
  const renderComponet = () => {
    switch (type) {
      case COMPONENT_TYPES.VERTICAL: {
        return (
          <Slider
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: "slider-vertical",
              },
            }}
            step={step}
            aria-label={label}
            orientation="vertical"
            size="Small"
            defaultValue={defaultVal}
            min={min}
            max={max}
            onChange={onChange}
            value={value}
            marks={marks}
          />
        );
      }
      default: {
        return (
            <Slider
              aria-label={label}
              step={step}
              size="Small"
              defaultValue={defaultVal}
              min={min}
              max={max}
              onChange={onChange}
              value={value}
              marks={marks}
              color="#82ca9d"
              track={false}
          />
        );
      }
    }
  };
  return renderComponet();
};

export default SliderWrapper;
export { COMPONENT_TYPES };
