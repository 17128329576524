import React from "react";
import {
  ComposedChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceDot,
  Label,
} from "recharts";
import useChartWidth from "../../hooks/useChartWidth";

const nitroConstants = [
  { ratio: 4, adjustmentFactor: 0.7 },
  { ratio: 5, adjustmentFactor: 0.79 },
  { ratio: 6, adjustmentFactor: 0.87 },
  { ratio: 7, adjustmentFactor: 0.94 },
  { ratio: 8, adjustmentFactor: 1 },
  { ratio: 9, adjustmentFactor: 1.05 },
  { ratio: 10, adjustmentFactor: 1.1 },
  { ratio: 11, adjustmentFactor: 1.14 },
  { ratio: 12, adjustmentFactor: 1.16 },
  { ratio: 13, adjustmentFactor: 1.18 },
  { ratio: 14, adjustmentFactor: 1.2 },
];

const NitrogenCostChart = ({ id, chartDataPoint, title }) => {
  const width = useChartWidth(id, window.innerWidth);

  const xTicks = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const yTicks = [0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2];

  return (
    <div id={id} className="w-full h-full">
      <div className="font-bold mb-3 ml-8">{title}</div>
      <ComposedChart
        width={width}
        height={350}
        data={nitroConstants}
        margin={{ top: 50, right: 20, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="ratio"
          type="number"
          domain={[4, 14]}
          allowDecimals={true}
          ticks={xTicks}
        >
          <Label
            value="Corn Price: N Price ratio"
            offset={-10}
            position="insideBottom"
          />
        </XAxis>
        <YAxis
          type="number"
          domain={[0.6, 1.2]}
          allowDecimals={true}
          ticks={yTicks}
        >
          <Label value="Adjustment Factor" angle={-90} position="insideLeft" />
        </YAxis>

        <Line type="monotone" dataKey="adjustmentFactor" stroke="#82ca9d" />

        {chartDataPoint && (
          <ReferenceDot
            x={chartDataPoint.ratio}
            y={chartDataPoint.adjustmentFactor}
            r={10}
            fill="yellow"
            stroke="none"
            isFront={true}
          >
            <Label
              value={`Ratio: ${chartDataPoint.ratio.toFixed(2)}, Adjustment: ${chartDataPoint.adjustmentFactor.toFixed(2)}`}
              position="top"
              fill="#000"
              fontSize={16}
            />
          </ReferenceDot>
        )}
      </ComposedChart>
    </div>
  );
};

export default NitrogenCostChart;