import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import Spinner from './Spinner';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function Modal({
  isOpen, close, Content, contentProps, small, title, className
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={twMerge(
              'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity',
            )}
          />
        </Transition.Child>

        <div className="fixed z-100 inset-0">
          <div className={twMerge(
            'flex items-end sm:items-center justify-center min-h-full',
            'p-4 text-center sm:p-0',
          )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'relative bg-white dark:bg-gray-900 dark:text-white text-left',
                  'shadow-xl transform transition-all',
                  'sm:w-full modal',
                  small ? 'sm:max-w-sm' : 'sm:max-w-5xl',
                  className,
                )}
                style={{ borderRadius: '1.5rem' }}
              >
                {title && (
                <div className="p-4 border-b border-gray-200 flex items-center justify-between font-bold text-lg">
                  <h4>{title}</h4>
                  <XMarkIcon onClick={close} className="cursor-pointer" width={20} height={20} />
                </div>
                )}
                <div className="p-4 sm:p-6">
                  <Suspense fallback={<Spinner />}>
                    <Content close={close} {...contentProps} />
                  </Suspense>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  Content: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contentProps: PropTypes.object,
  small: PropTypes.bool,
};
Modal.defaultProps = {
  contentProps: {},
  small: false,
};

export function ModalHeader({ children }) {
  return (
    <Dialog.Title
      as="h3"
      className="text-lg leading-6 font-medium text-gray-900 mb-2 text-center"
    >
      {children}
    </Dialog.Title>
  );
}

export function ModalFooter({ children }) {
  return (
    <div className="mt-4 flex justify-evenly">
      {children}
    </div>
  );
}
