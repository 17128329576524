import React, { useEffect, useState } from "react";
// @ts-ignore
// @ts-nocheck
import SmartBanner from "react-smartbanner";

const SmartBannerWrapper = () => {
  const [platform, setPlatform] = useState("android");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setPlatform("android");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setPlatform("ios");
    } else {
      setPlatform("other");
    }
  }, []);

  if (platform === "other") {
    return null;
  }

  return (
    <div>
      
      <SmartBanner
        title="Producer Connect"
        author="Longitude 103"
        force={platform}
      />
      
    </div>
  );
};

export default SmartBannerWrapper;

