import React, { useState, useEffect } from "react";
import TraySlideOut from "../common/TraySlideOut";
import Toggle from "../common/Toggle";
import { FormikProvider, useFormik } from "formik";
import Input from "../common/form/Input";
import Select from "../common/form/Select";
import DatePicker from "../common/form/DatePicker";
import Button from "../common/Button";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from "yup";

const handlePercentInput = (formik, index, key, value) => {
  const n = `Nitrogen_Applications-${index + 1}-n_percent`;
  const p = `Nitrogen_Applications-${index + 1}-p_percent`;
  const k = `Nitrogen_Applications-${index + 1}-k_percent`;

  const numericValue = parseFloat(value);

  if (!isNaN(numericValue)) {
    if (key === n || key === p || key === k) {
      formik.setFieldValue(key, numericValue / 100);
    } else {
      formik.setFieldValue(key, numericValue);
    }
  } else {
    formik.setFieldValue(key, value);
  }
};

const percentValueToShow = (index, key, value) => {
  const n = `Nitrogen_Applications-${index + 1}-n_percent`;
  const p = `Nitrogen_Applications-${index + 1}-p_percent`;
  const k = `Nitrogen_Applications-${index + 1}-k_percent`;

  if (value === null || value === undefined) {
    return "";
  }

  if (key === n || key === p || key === k) {
    return value * 100;
  } else {
    return value;
  }
};

const handleAdvancedToggle = (formik, key, index, value) => {
  formik.setFieldValue(key, value);

  if (key.includes("advanced") && value === false) {
    const fieldsToReset = [
      `Nitrogen_Applications-${index + 1}-n_percent`,
      `Nitrogen_Applications-${index + 1}-p_percent`,
      `Nitrogen_Applications-${index + 1}-k_percent`,
      `Nitrogen_Applications-${index + 1}-n_density`,
      `Nitrogen_Applications-${index + 1}-type`,
      `Nitrogen_Applications-${index + 1}-amount`,
      `Nitrogen_Applications-${index + 1}-units`,
    ];

    fieldsToReset.forEach((fieldKey) => {
      if (formik.values[fieldKey] !== undefined) {
        formik.setFieldValue(fieldKey, "");
      }
    });
  }

  if (key.includes("advanced") && value === true) {
    formik.setFieldValue(`Nitrogen_Applications-${index + 1}-direct_nitrogen`, "");
  }


  if (key.includes("inhibitor_used") && value === false) {
    const inhibitorTypeKey = `Nitrogen_Applications-${
      index + 1
    }-inhibitor_type`;
    if (formik.values[inhibitorTypeKey] !== undefined) {
      formik.setFieldValue(inhibitorTypeKey, "");
    }
  }
};

const getDefaultInputs = (data) =>
  Object.entries(data).reduce((result, [key, value]) => {
    if (key === "Nitrogen_Inhibitors" || key === "Nitrogen_Applications") {
      if (value?.length) {
        value.forEach((nitrate, index) => {
          Object.entries(nitrate).forEach(([nitrateKey, nitrateValue]) => {
            result[`${key}-${index + 1}-${nitrateKey}`] = nitrateValue;
          });
          const nPercent =
            result[`Nitrogen_Applications-${index + 1}-n_percent`];
          const amount = result[`Nitrogen_Applications-${index + 1}-amount`];
          const type = result[`Nitrogen_Applications-${index + 1}-type`];
          const units = result[`Nitrogen_Applications-${index + 1}-units`];
          result[`Nitrogen_Applications-${index + 1}-advanced_config`] =
            nPercent || amount || type || units ? true : false;

          if (
            result[`Nitrogen_Applications-${index + 1}-inhibitor_used`] ===
            undefined
          ) {
            result[`Nitrogen_Applications-${index + 1}-inhibitor_used`] = false;
          }
        });
        result[key] = value.length;
      }
    } else {
      result[key] = value;
    }
    return result;
  }, {});

const CropInfo = ({
  data,
  open,
  setIsOpen,
  isAdding,
  tractId,
  refreshData,
}) => {
  const [isLoading, setLoading] = useState(false);

  const defaultInputs = getDefaultInputs(data || {});

  const [isEditing, setIsEditing] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState(null);

  const [nitrogenAppCount, setNitrogenAppCount] = useState(0);

  const [newNitrogenApplications, setNewNitrogenApplications] = useState([]);

  const generateNitrogenApplicationSchema = (count) => {
    const schema = {};

    for (let i = 1; i <= count; i++) {
      schema[`Nitrogen_Applications-${i}-date_applied`] = Yup.date()
        .required("Date applied is required")
        .typeError("Invalid date");

      schema[`Nitrogen_Applications-${i}-emergent_type`] =
        Yup.string().required("Emergence type is required");

      schema[`Nitrogen_Applications-${i}-inhibitor_type`] = Yup.lazy(
        (value, ctx) => {
          const formValues = ctx?.parent || {};
          const inhibitorUsed =
            formValues[`Nitrogen_Applications-${i}-inhibitor_used`];
          return inhibitorUsed
            ? Yup.string().required("Inhibitor type is required")
            : Yup.mixed().notRequired();
        }
      );
      schema[`Nitrogen_Applications-${i}-n_density`] = Yup.lazy(
        (value, ctx) => {
          const formValues = ctx?.parent || {};
          const advanced =
            formValues[`Nitrogen_Applications-${i}-advanced_config`];
          return advanced
            ? Yup.number()
                .required("N Density is required")
                .min(1, "N Density must be greater than zero")
            : Yup.mixed().notRequired();
        }
      );
      schema[`Nitrogen_Applications-${i}-n_percent`] = Yup.lazy(
        (value, ctx) => {
          const formValues = ctx?.parent || {};
          const advanced =
            formValues[`Nitrogen_Applications-${i}-advanced_config`];
          return advanced
            ? Yup.number()
                .required("N Percent is required")
                .min(0.01, "N Percent must be greater than zero")
            : Yup.mixed().notRequired();
        }
      );
      schema[`Nitrogen_Applications-${i}-amount`] = Yup.lazy((value, ctx) => {
        const formValues = ctx?.parent || {};
        const advanced =
          formValues[`Nitrogen_Applications-${i}-advanced_config`];
        return advanced
          ? Yup.number()
              .required("Amount is required")
              .min(0.01, "Amount must be greater than zero")
          : Yup.mixed().notRequired();
      });
      schema[`Nitrogen_Applications-${i}-units`] = Yup.lazy((value, ctx) => {
        const formValues = ctx?.parent || {};
        const advanced =
          formValues[`Nitrogen_Applications-${i}-advanced_config`];
        return advanced
          ? Yup.string()
              .required("Units are required")
              .min(0.01, "Units must be greater than zero")
          : Yup.mixed().notRequired();
      });
    }

    return schema;
  };

  const validationSchema = Yup.object().shape({
    Crop_Planted: Yup.string().required("Crop planted is required"),
    Year: Yup.number()
      .required("Year is required")
      .typeError("Year must be a number"),
    ...generateNitrogenApplicationSchema(nitrogenAppCount),
  });

  useEffect(() => {
    if (data && data.Nitrogen_Applications) {
      setNitrogenAppCount(data.Nitrogen_Applications.length);
    }
  }, [data]);

  const handleButtonClick = () => {
    refreshData();
    if (isEditing) {
      setIsEditing(false);
    } else {
      formik2.resetForm({ values: defaultInputs });
      setIsEditing(true);
    }
  };

  const handleDeleteNitro = async (index) => {
    try {
      const nitrogenApplicationId =
        defaultInputs[`Nitrogen_Applications-${index + 1}-_id`];
      if (!nitrogenApplicationId) {
        toast.error("No ID found for the selected application");
        return;
      }
      await request({
        method: "delete",
        url: `/tracts/${tractId}/crops/${data._id}/nitrogen/${nitrogenApplicationId}`,
      });
      toast.success(`Successfully deleted nitrogen application`);
      refreshData();
    } catch (error) {
      toast.error(`Error deleting nitrogen application: ${error.message}`);
    }
  };

  const handleAddNitro = (e) => {
    e.preventDefault();

    const newNitrogenAppIndex = nitrogenAppCount + 1;

    setNewNitrogenApplications([
      ...newNitrogenApplications,
      newNitrogenAppIndex,
    ]);

    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-date_applied`,
      ""
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-emergent_type`,
      ""
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-type`,
      ""
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-amount`,
      ""
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-units`,
      ""
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-inhibitor_used`,
      false
    );
    formik2.setFieldValue(
      `Nitrogen_Applications-${newNitrogenAppIndex}-inhibitor_type`,
      ""
    );

    setNitrogenAppCount(newNitrogenAppIndex);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        await request({
          method: "post",
          url: `/tracts/${tractId}/AddCrop`,
          data: values,
        });
        refreshData();
        toast.success("Successfully added crop");
        setLoading(null);
        setIsOpen(false);
        resetForm();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const formik2 = useFormik({
    enableReinitialize: true,
    initialValues: defaultInputs,
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        const modifiedData = { general: {}, nitrogen: {} };
        const newNitrogenApplications = [];

        Object.keys(values).forEach((key) => {
          if (!(key in defaultInputs) || values[key] !== defaultInputs[key]) {
            if (key.startsWith("Nitrogen_Applications")) {
              const [prefix, index, field] = key.split("-");

              if (!modifiedData.nitrogen[index]) {
                modifiedData.nitrogen[index] = {};
              }

              if (
                !defaultInputs[`Nitrogen_Applications-${index}-date_applied`] &&
                field === "date_applied"
              ) {
                newNitrogenApplications.push({
                  date_applied:
                    values[`Nitrogen_Applications-${index}-date_applied`],
                  emergent_type:
                    values[`Nitrogen_Applications-${index}-emergent_type`],
                  type: values[`Nitrogen_Applications-${index}-type`],
                  amount: values[`Nitrogen_Applications-${index}-amount`],
                  units: values[`Nitrogen_Applications-${index}-units`],
                  inhibitor_used:
                    values[`Nitrogen_Applications-${index}-inhibitor_used`],
                  inhibitor_type:
                    values[`Nitrogen_Applications-${index}-inhibitor_type`],
                  direct_nitrogen:
                    values[`Nitrogen_Applications-${index}-direct_nitrogen`],
                  k_percent: values[`Nitrogen_Applications-${index}-k_percent`],
                  n_percent: values[`Nitrogen_Applications-${index}-n_percent`],
                  p_percent: values[`Nitrogen_Applications-${index}-p_percent`],
                  n_density: values[`Nitrogen_Applications-${index}-n_density`],
                });
              } else {
                modifiedData.nitrogen[index][field] = values[key];
              }
            } else {
              modifiedData.general[key] = values[key];
            }
          }
        });
        if (Object.keys(modifiedData.general).length > 0) {
          await request({
            method: "patch",
            url: `/tracts/${tractId}/crops/${data._id}`,
            data: modifiedData.general,
          });
          toast.success("Successfully updated crop data");
        }
        for (const [index, appData] of Object.entries(modifiedData.nitrogen)) {
          const formApplicationId =
            defaultInputs[`Nitrogen_Applications-${index}-_id`];
          if (formApplicationId) {
            await request({
              method: "patch",
              url: `/tracts/${tractId}/crops/${data._id}/nitrogen/${formApplicationId}`,
              data: appData,
            });
          }
        }
        for (const newApp of newNitrogenApplications) {
          await request({
            method: "post",
            url: `/tracts/${tractId}/crops/${data._id}/nitrogen/`,
            data: newApp,
          });
          toast.success("Successfully added new nitrogen application");
        }

        refreshData();
        resetForm();
        setIsOpen(false);
        setIsEditing(false);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const renderItem = ({
    label,
    key,
    suffix,
    bool,
    textarea,
    number,
    dropdown,
    options,
    date,
    conditional_dropdown,
    toggle,
    index,
    comment,
  }) => {
    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );
    const labelToShow = label || key.split("_").join(" ");
    let valueToShow = defaultInputs?.[key];
    if (key.includes(".")) {
      const [key1, key2] = key.split(".");
      valueToShow = defaultInputs?.[key1] && defaultInputs?.[key1][key2];
    }
    valueToShow = percentValueToShow(index, key, valueToShow);
    const showError = formik2.errors[key];
    if (bool) {
      return (
        <div key={key} className="sm:col-span-1">
          <Toggle
            key={key}
            value={
              isEditing
                ? formik2.values[key] !== undefined &&
                  formik2.values[key] !== null
                  ? formik2.values[key]
                  : valueToShow
                : formik2.values[key]
            }
            label={labelToShow}
            onChange={(value) => {
              handleAdvancedToggle(formik2, key, index, value);
            }}
          />
          {showError && (
            <div className="text-red-600">{formik2.errors[key]}</div>
          )}
        </div>
      );
    }
    if (isAdding) {
      if (dropdown) {
        return (
          <div>
            <Select
              name={key}
              label={labelToShow}
              extraOptions={options}
              onChange={(option) => {
                formik.setFieldValue(key, option);
              }}
              className={showError ? "border-red-600" : ""}
            />
            {showError && (
              <div className="text-red-600">{formik2.errors[key]}</div>
            )}
          </div>
        );
      }
      return (
        <div>
          <Input
            name={key}
            label={labelToShow}
            textarea={textarea}
            suffix={suffix}
            value={percentValueToShow(index, key, formik2.values[key] ?? valueToShow)}
            type={number ? "number" : "text"}
            disabled={key === `Nitrogen_Applications-${index + 1}-direct_mitrogen` && formik2.values[`Nitrogen_Applications-${index + 1}-advanced_config`] === true}
            onChange={(e) => {
              handlePercentInput(formik2, index, key, e.target.value);
            }}
            className={showError ? "border-red-600" : ""}
          />
          {showError && (
            <div className="text-red-600">{formik2.errors[key]}</div>
          )}
        </div>
      );
    }
    if (isEditing) {
      if (
        conditional_dropdown === "inhibitor" &&
        formik2.values[`Nitrogen_Applications-${index + 1}-inhibitor_used`] ===
          true
      ) {
        return (
          <div>
            <Select
              name={key}
              label={labelToShow}
              extraOptions={options}
              value={formik2.values[key] ?? valueToShow}
              onChange={(option) => {
                formik2.setFieldValue(key, option);
              }}
              className={showError ? "border-red-600" : ""}
            />
            {showError && (
              <div className="text-red-600">{formik2.errors[key]}</div>
            )}
          </div>
        );
      }
      if (
        conditional_dropdown === "advanced" &&
        formik2.values[`Nitrogen_Applications-${index + 1}-advanced_config`] ===
          true
      ) {
        if (dropdown) {
          return (
            <div>
              <Select
                name={key}
                label={labelToShow}
                extraOptions={options}
                value={formik2.values[key] ?? valueToShow}
                onChange={(option) => {
                  formik2.setFieldValue(key, option);
                }}
                className={showError ? "border-red-600" : ""}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Input
                name={key}
                label={labelToShow}
                value={percentValueToShow(index, key, formik2.values[key] ?? valueToShow)}
                textarea={textarea}
                suffix={suffix}
                type={number ? "number" : "text"}
                disabled={
                  (key === "Yield_Goal" &&
                  formik2.values["Crop_Planted"] !== "Corn") ||
                  (key === `Nitrogen_Applications-${index + 1}-direct_nitrogen` && formik2.values[`Nitrogen_Applications-${index + 1}-advanced_config`] === true)
                }
                onChange={(e) => {
                  handlePercentInput(formik2, index, key, e.target.value);
                }}
                className={showError ? "border-red-600" : ""}
              />
              {inputComment}
            </div>
          );
        }
      }
      if (toggle) {
        return (
          <div>
            <span className="text-sm font-medium text-gray-900 mb-2">
              {labelToShow}
            </span>
            <Toggle
              value={formik2.values[key] ?? false}
              onChange={(value) => {
                handleAdvancedToggle(formik2, key, index, value);
              }}
              className={showError ? "border-red-600 m-2" : "m-2"}
            />
            {showError && (
              <div className="text-red-600">{formik2.errors[key]}</div>
            )}
          </div>
        );
      }
      if (date) {
        return (
          <div>
            <DatePicker
              name={key}
              inputClassName="h-[38px]"
              label={labelToShow}
              value={formik2.values[key] ?? valueToShow}
              onChange={(e) => formik2.setFieldValue(key, e.target.value)}
              className={showError ? "border-red-600 m-2" : "m-2"}
            />
            {showError && (
              <div className="text-red-600">{formik2.errors[key]}</div>
            )}
          </div>
        );
      }
      if (!conditional_dropdown) {
        if (dropdown) {
          return (
            <div>
              <Select
                name={key}
                label={labelToShow}
                extraOptions={options}
                value={formik2.values[key] ?? valueToShow}
                onChange={(option) => {
                  formik2.setFieldValue(key, option);
                }}
                className={showError ? "border-red-600" : ""}
              />
              {showError && (
                <div className="text-red-600">{formik2.errors[key]}</div>
              )}
            </div>
          );
        } else {
          return (
            <div>
              <Input
                name={key}
                label={labelToShow}
                value={percentValueToShow(index, key, formik2.values[key] ?? valueToShow)}
                textarea={textarea}
                suffix={suffix}
                type={number ? "number" : "text"}
                disabled={
                  (key === "Yield_Goal" &&
                  formik2.values["Crop_Planted"] !== "Corn") ||
                  (key === `Nitrogen_Applications-${index + 1}-direct_nitrogen` && formik2.values[`Nitrogen_Applications-${index + 1}-advanced_config`] === true)
                }
                onChange={(e) => {
                  handlePercentInput(formik2, index, key, e.target.value); 
                }}
                className={showError ? "border-red-600" : ""}
              />
              {inputComment}
              {showError && (
                <div className="text-red-600">{formik2.errors[key]}</div>
              )}
            </div>
          );
        }
      }
    }
    if (!isEditing) {
      let valueToDisplay = valueToShow;
      let labelToDisplay = labelToShow;
      if (typeof valueToShow === "boolean") {
        valueToDisplay = valueToShow ? "True" : "False";
      }
      if (date && valueToShow) {
        const parsedDate = new Date(valueToShow);
        if (!isNaN(parsedDate)) {
          valueToDisplay = parsedDate.toLocaleDateString();
        }
      }
      if (
        key.includes("inhibitor_type") &&
        formik2.values[`Nitrogen_Applications-${index + 1}-inhibitor_used`] ===
          false
      ) {
        return null;
      }
      if (
        key.includes("advanced") || 
        ((key.includes("n_percent") ||
          key.includes("n_density") ||
          key.includes("k_percent") ||
          key.includes("p_percent") ||
          key.includes("amount") ||
          key.includes("units") ||
          key.includes("type")) &&
          formik2.values[
            `Nitrogen_Applications-${index + 1}-advanced_config`
          ] === false)
      ) {
        return null;
      }
      return (
        <div key={key} className="sm:col-span-1">
          <label className="font-semibold text-sm">{labelToDisplay}: </label>
          <div className="mt-1 text-sm">
            {valueToDisplay} {suffix || ""}
          </div>
        </div>
      );
    }
  };

  const gridClass = "w-100 grid grid-cols-1 md:grid-cols-2 gap-4 mt-3";

  if (!data && !isAdding) {
    return null;
  }

  const fields = (
    <div className="p-6">
      <div className={`${gridClass} mb-4 mt-6 first:mt-0 last:mb-0`}>
        {[
          { key: "Year", number: true },
          {
            key: "Crop_Planted",
            dropdown: true,
            options: [
              "Alfalfa",
              "Corn",
              "Dry Beans",
              "Fallow",
              "Field Beans",
              "Grain Sorghum",
              "Grass",
              "Millet",
              "Oats",
              "Other",
              "Potatoes",
              "Small Grains",
              "Sorghum / Sudan Grass",
              "Sorghum Sudan Grass",
              "Soybeans",
              "Sugar Beets",
              "Sunflowers",
              "Wheat",
            ],
          },
          { key: "Crop_Yield", number: true },
          {
            key: "Crop_Yield_Units",
            dropdown: true,
            options: ["bushels / ac", "pounds / ac", "tons / ac"],
          },
          {
            key: "Yield_Goal",
            number: true,
          },
          { key: "Sprinkler_Acres", number: true },
          { key: "Flood_Acres", number: true },
          { key: "Other_Irr_Acres", number: true },
          { key: "Irrigation_Water_Applied", suffix: "inches", number: true },
          { key: "Water_Nitrate", suffix: "ppm NO-3", number: true },
          {
            key: "Tillage_Practice",
            dropdown: true,
            options: ["Conventional", "Minimum", "No-till"],
          },
        ].map(renderItem)}
      </div>
      <div className={`w-100 grid grid-cols-1 gap-4 mt-3 first:mt-0 last:mb-0`}>
        {[
          {
            key: "Note",
            label: "Note",
            textarea: true,
          },
        ].map(renderItem)}
      </div>
      <hr class="mt-6" />
      {defaultInputs?.Nitrogen_Applications ? (
        <>
          <div className="mb-4 mt-3">
            <div>
              <div className="flex items-center justify-between">
                <h5 className="text-base font-semibold my-2">
                  Nitrogen Applied
                </h5>
                {isEditing && (
                  <Button onClick={(e) => handleAddNitro(e)}>
                    Add Application
                  </Button>
                )}
              </div>
            </div>
            {Array.from({ length: nitrogenAppCount }, (_, index) => (
              <div
                key={index}
                className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0 `}
              >
                <div className="font-bold col-span-2 flex items-center justify-between">
                  <span>Applications {index + 1}</span>
                  {isEditing &&
                    ((defaultInputs.Crop_Planted === "Corn" &&
                      nitrogenAppCount > 1) ||
                    defaultInputs.Crop_Planted !== "Corn" ? (
                      <MinusCircleIcon
                        className="w-5 h-5 cursor-pointer text-red-600"
                        onClick={() => {
                          setDeleteIndex(index);
                          setModalOpen(true);
                        }}
                      />
                    ) : null)}
                </div>
                {[
                  {
                    key: `Nitrogen_Applications-${index + 1}-date_applied`,
                    label: "Date Applied",
                    date: true,
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-emergent_type`,
                    label: "Emergence Type",
                    dropdown: true,
                    options: ["Pre-emergence", "Post-emergence"],
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-direct_nitrogen`,
                    label: "Nitrogen Applied",
                    number: true,
                    suffix: "lbs/ac",
                    comment: "See advanced for more options",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-inhibitor_used`,
                    label: "Inhibitir Used",
                    toggle: true,
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-advanced_config`,
                    label: "Advanced Configuration",
                    toggle: true,
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-inhibitor_type`,
                    label: "Inhibitor Type",
                    conditional_dropdown: "inhibitor",
                    options: [
                      "Nitrification Inhibitor",
                      "Urease Inhibitor",
                      "Nitrification & Urease Inhibitor",
                      "Biologic",
                    ],
                  },
                  //////// Advanced //////////
                  {
                    key: `Nitrogen_Applications-${index + 1}-amount`,
                    label: "Amount",
                    number: true,
                    conditional_dropdown: "advanced",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-type`,
                    label: "Type",
                    conditional_dropdown: "advanced",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-units`,
                    label: "Units",
                    dropdown: true,
                    options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
                    conditional_dropdown: "advanced",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-n_percent`,
                    label: "Nitrogen",
                    number: true,
                    conditional_dropdown: "advanced",
                    suffix: "%",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-k_percent`,
                    label: "Potassium",
                    number: true,
                    conditional_dropdown: "advanced",
                    suffix: "%",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-p_percent`,
                    label: "Phosphate",
                    number: true,
                    conditional_dropdown: "advanced",
                    suffix: "%",
                  },
                  {
                    key: `Nitrogen_Applications-${index + 1}-n_density`,
                    label: "Nitrogen Density",
                    number: true,
                    conditional_dropdown: "advanced",
                    suffix: "lbs./gallon",
                  },
                ].map((item) => renderItem({ ...item, index }))}
              </div>
            ))}
          </div>
          <hr class="mt-6" />
        </>
      ) : null}
      {defaultInputs?.Nitrogen_Inhibitors ? (
        <div className="mb-4 mt-3">
          <h5 className="text-base font-semibold inline my-2 mx-auto">
            Nirogen Inhibitor
          </h5>
          {Array(defaultInputs.Nitrogen_Inhibitors || 0)
            .fill("")
            .map((item, index) => (
              <div
                key={index}
                className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0`}
              >
                <div className="font-bold col-span-2">
                  Inhibitors {index + 1}
                </div>
                {[
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-date_applied`,
                    label: "Date Applied",
                    date: true,
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-type`,
                    label: "Type",
                    dropdown: true,
                    options: ["Biologic", "Chemical"],
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-name`,
                    label: "Name",
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-amount`,
                    label: "Amount",
                    number: true,
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-units`,
                    label: "Units",
                    dropdown: true,
                    options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-acres_treated`,
                    label: "Acres Treated",
                    number: true,
                  },
                ].map(renderItem)}
              </div>
            ))}
        </div>
      ) : null}
      <div className="flex justify-start">
        {isEditing && (
          <div>
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              Save
            </Button>
          </div>
        )}
        {data && data.approved === false && (
          <div>
            <Button onClick={handleButtonClick} className="ml-2">
              {!isEditing ? "Edit" : "Cancel"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <TraySlideOut
        title="Crop Information"
        onClose={() => {
          setTimeout(() => setIsOpen(false), 100);
          setIsEditing(false);
          refreshData();
        }}
        open={open}
      >
        {isAdding ? (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>{fields}</form>
          </FormikProvider>
        ) : isEditing ? (
          <FormikProvider value={formik2}>
            <form onSubmit={formik2.handleSubmit}>{fields}</form>
          </FormikProvider>
        ) : (
          fields
        )}
      </TraySlideOut>
      <ConfirmModal
        isOpen={isModalOpen}
        toggle={() => {
          setModalOpen(false);
        }}
        message="Are you sure you want to delete this application event?"
        onConfirm={() => {
          handleDeleteNitro(deleteIndex);
          setModalOpen(false);
          refreshData();
        }}
        warning={true}
      />
    </div>
  );
};

export default CropInfo;
